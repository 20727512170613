body {
    font-family: "Mulish", sans-serif;
}

h1,
h2,
h3 {
    font-family: "Gravitas One", cursive;
}

h2 {
    color: rgb(255, 0, 212);
}

h5 {
    font-weight: bold;
}

header h2 {
    color: rgb(255, 0, 212);
}

.site-footer {
    background-image: linear-gradient(white, rgb(236, 61, 207));
    padding: 100px 0;
}

.jumbotron {
    margin: 0;
    padding: 30px;
    color: white;
}

.row-content {
    padding: 15px 0;
}

.navbar {
    background-color: #a8f0b1;
}

.navbar-nav .nav-item .nav-link {
    color: rgb(255, 0, 212);
}

.nav-item {
    padding: 5px;
}

.nav-item.active,
.nav-item:hover {
    border-radius: 5px;
    background-color: rgb(255, 0, 212);
    color: white;
}

.nav-item > .nav-link.active {
    border-radius: 5px;
    background-color: rgb(255, 0, 212);
    color: white;
}

/* Booking button */
.navbar-text {
    border-radius: 5px;
    padding: 10px;
    background-color: #c507f5;
}

.tab-content {
    border: solid rgb(255, 0, 212);
    border-radius: 5px;
    padding: 15px;
}

a,
a:hover {
    color: inherit;
    text-decoration: none;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.carousel {
    border: solid rgb(255, 0, 212);
}

.card {
    background: #fff;
    border-radius: 0.6em;
    margin: 1em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
        0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
        0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
}

.card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
        0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
        0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card-header,
.modal-header {
    color: white;
    background-color: rgb(255, 0, 212);
}

img.zoom {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
        0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
        0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}

img.zoom:hover {
    transform: scale(1.1);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
        0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
        0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.row-gallery {
    padding: 10px 0px 40px 0px;
}

/* Booking screen */
input {
    font-size: 22px;
    color: #000;
    padding: 18px 22px;
    font-size: 16px;
    margin-bottom: 17px;
    border: 2;
    display: block;
    width: 100%;
}

form button {
    width: 100%;
    margin-top: 10px;
}

.user-profile {
    position: fixed;
    bottom: 20px;
    right: 40px;
    width: 100px;
    height: 100px;
}

.user-profile img {
    border-radius: 50%;
    max-width: 100%;
}
